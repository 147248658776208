import { parseExternalLinks } from 'src/parsers';
import PropTypes from 'prop-types';
import React from 'react';

const ParsedExternalLinkString = ({ text }) => (
    <div dangerouslySetInnerHTML={{ __html: parseExternalLinks(text) }} />
);

ParsedExternalLinkString.propTypes = {
    text: PropTypes.string.isRequired
};

export default ParsedExternalLinkString;
