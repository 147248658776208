import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import styles from 'src/stylesheets/table/post.scss';
import fallBackImg from 'src/resources/illustrations/no_image.png';
import ImageWithFallback from 'src/components/ImageWithFallback';
import { parseExternalLinks } from 'src/parsers';
import InternalPostDetailLink from 'src/components/chartViews/fixedDataTable/tableCellRenderers/partials/InternalPostDetailLink';

const Post = (props) => {
    const {
        headline,
        contentItems,
        link,
        internalId,
        linkText,
        additionalCustomLink,
        picture,
        cellSetting
    } = props;

    // we join content items into a single string
    const contentItemArray = [];
    contentItems.forEach((contentItem) => {
        if (contentItem.content) {
            contentItemArray.push(contentItem.content);
        }
    });
    const content = contentItemArray.join(' | ');
    const { showLink } = cellSetting;
    return (
        <>
            <div className={styles.head}>
                {headline}
                {
                    showLink !== 'none' && (
                        <span className={styles.link}>
                            {
                                (internalId && showLink === 'in-app')
                                && <InternalPostDetailLink postId={internalId} />
                            }
                            {
                                (link && (showLink === 'public' || !internalId))
                                && (
                                    <a href={link} target="_blank" rel="noopener noreferrer">
                                        {linkText}
                                    </a>
                                )
                            }
                        </span>
                    )
                }
                {
                    additionalCustomLink && (
                        <span className={styles.link}>
                            {additionalCustomLink}
                        </span>
                    )
                }
            </div>
            <div className={styles.body}>
                {
                    content && (
                        <div className={styles.content} dangerouslySetInnerHTML={{ __html: parseExternalLinks(content) }} />
                    )
                }
                {
                    !content
                    && <div className={styles.noContent}>This post contains no text</div>
                }
                {
                    picture && (
                        <div className={styles.attachment}>
                            <ImageWithFallback
                              src={picture}
                              fallbackSrc={fallBackImg}
                              className={styles.image}
                            />
                        </div>
                    )
                }
            </div>
        </>
    );
};

Post.propTypes = {
    headline: PropTypes.node.isRequired,
    link: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    additionalCustomLink: PropTypes.node,
    contentItems: customPropTypes.postContentItems.isRequired,
    internalId: PropTypes.string,
    picture: PropTypes.string,
    cellSetting: customPropTypes.tableCellSetting.isRequired
};

Post.defaultProps = {
    additionalCustomLink: undefined,
    picture: ''
};

export default Post;
