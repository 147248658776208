import styles from 'src/stylesheets/postTags.scss';
import React from 'react';
import { makeSelectActivePostTagsByIds } from 'src/selectors/postTags';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import ResponsiveEditableItem from 'src/components/ResponsiveEditableItem';
import PostPostTagListItem from 'src/components/views/postTags/PostPostTagListItem';

const ActivePostTags = (props) => {
    const { postTags } = props;

    if (postTags.length > 0) {
        return (
            postTags.map((postTag) => (
                <div key={postTag.id} className={styles.rowFlexParent}>
                    <div className={styles.rowFlexChild}>
                        <ResponsiveEditableItem name={postTag.name} />
                    </div>
                </div>
            ))
        );
    }
    return (
        <div key="no-tags" className={styles.rowFlexParent}>
            <div className={styles.rowFlexChild}>
                <PostPostTagListItem name="No tags" disabled />
            </div>
        </div>
    );
};

ActivePostTags.propTypes = {
    postTags: customPropTypes.postTags.isRequired
};

const makeMapStateToProps = () => {
    const selectActivePostTagsByIds = makeSelectActivePostTagsByIds();
    return (state, ownProps) => {
        const { postTagIds } = ownProps;
        return {
            postTags: selectActivePostTagsByIds(state, postTagIds)
        };
    };
};

export default connect(makeMapStateToProps)(ActivePostTags);
