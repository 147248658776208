import React from 'react';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/filter.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import { makeSelectPostTagById } from 'src/selectors/postTags';
import { connect } from 'react-redux';
import PostTagName from 'src/components/filter/PostTagName';
import CommaSeparatedValueList from 'src/components/filter/CommaSeparatedValueList';
import FilterItem from 'src/components/filter/FilterItem';

const makeMapStateToPropsForPostTagName = () => {
    const selectPostTagById = makeSelectPostTagById();
    return (state, ownProps) => {
        const postTag = selectPostTagById(state, ownProps.postTagId);
        return {
            name: postTag ? postTag.name : 'Invalid Tag'
        };
    };
};

const ConnectedPostTagName = connect(makeMapStateToPropsForPostTagName)(PostTagName);

const PostTagFilterSummary = (props) => {
    const {
        state,
        input,
        hasCustomBinding
    } = props;

    const {
        values,
        conjunction,
        untagged,
    } = input;

    let content = '';
    if (untagged === true) {
        content = <span>Untagged posts only</span>;
    } else {
        const postTagNames = values.map((postTagId) => <ConnectedPostTagName key={postTagId} postTagId={postTagId} />);
        content = <CommaSeparatedValueList items={postTagNames} conjunctionWord={conjunction === 'all' ? 'and' : 'or'} />;
    }

    return (
        <FilterItem
          state={state}
          hasCustomBinding={hasCustomBinding}
          icon={<IcomoonIcon icon="tag" className={styles.icon} />}
          content={content}
        />
    );
};

PostTagFilterSummary.propTypes = {
    state: PropTypes.oneOf(['fullyApplied', 'partiallyApplied', 'notApplied']).isRequired,
    input: customPropTypes.postTagFilter.isRequired,
    hasCustomBinding: PropTypes.bool
};

PostTagFilterSummary.defaultProps = {
    hasCustomBinding: false
};

export default PostTagFilterSummary;
